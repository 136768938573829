import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ElementRef,
  Renderer2,
  HostBinding,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../modules/users/services';
import { AfaqyHelper, AppConfig } from '../../../common/classes';
import { AuthService } from '../../services/auth.service';
import { takeWhile } from 'rxjs/operators';
import { trigger, transition, useAnimation } from '@angular/animations';
import {
  showAnimation,
  HideAnimation,
} from 'app/shared/animations/show-hide-menu';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'afaqy-menu',
  templateUrl: './menu.component.html',
  exportAs: 'AfaqyMenu',
  styleUrls: ['./menu.component.scss'],
  animations: [
    trigger('showHide', [
      transition('void => *', [
        useAnimation(showAnimation, {
          params: {
            timings: '300ms ease-in-out',
            axis: 'X',
            opacity: 1,
            hidepercent: 'calc(var(--direction-multiplier) * -1 * 100%)',
            showpercent: '0',
          },
        }),
      ]),
      transition('* => void', [
        useAnimation(HideAnimation, {
          params: {
            timings: '300ms ease-in-out',
            axis: 'X',
            opacity: 1,
            hidepercent: 'calc(var(--direction-multiplier) * -1 * 100%)',
            showpercent: '0',
          },
        }),
      ]),
    ]),
    trigger('fadeIn', [
      transition('void => *', [
        useAnimation(showAnimation, {
          params: {
            timings: '300ms ease-in-out',
            axis: 'X',
            opacity: 0,
            hidepercent: '0',
            showpercent: '0',
          },
        }),
      ]),
      transition('* => void', [
        useAnimation(HideAnimation, {
          params: {
            timings: '300ms ease-in-out',
            axis: 'X',
            opacity: 0,
            hidepercent: '0',
            showpercent: '0',
          },
        }),
      ]),
    ]),
  ],
})
export class MenuComponent implements OnInit, OnDestroy {
  @Input() fullMap: boolean;
  @Input() MenuOpen: boolean;
  @Output() MenuState: EventEmitter<boolean> = new EventEmitter();
  @Output() dataMapStatus: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('sidebar') sidebar: ElementRef;
  @HostBinding('@showHide')
  @HostBinding('@fadeIn')
  animation = true;
  config = AppConfig;
  alive: boolean = true;
  cid = 'menu';
  searchTerms = '';
  menuClass = '';
  smallSize: boolean = false;
  activeMenus = [];
  menuItems = [
    {
      link: ['/'],
      id: 'menu.monitoring',
      label: '',
      permissions: 'units-lists',
      iconClass: 'afaqy-icon-monitoring',
      active: !1,
      default: !0,
    },
    {
      link: '',
      id: 'menu.iot_monitoring',
      label: '',
      permissions: 'inventory_rooms-lists',
      iconClass: 'fas fa-tachometer-alt',
      active: !1,
      default: !0,
      childs: [
        {
          link: ['/iot_monitoring'],
          id: 'menu.senseware_monitoring',
          label: '',
          permissions: 'inventory_rooms-lists',
          iconClass: 'fas fa-warehouse',
          active: !1,
          default: !0,
        },
        {
          link: ['/warehouses'],
          id: 'menu.warehouses',
          label: '',
          permissions: 'warehouses-lists',
          iconClass: 'mdi mdi-store',
          active: !1,
          default: !0,
        },
        {
          link: ['/inventory-rooms'],
          id: 'menu.inventory-rooms',
          label: '',
          permissions: 'inventory_rooms-lists',
          iconClass: 'mdi mdi-collage',
          active: !1,
          default: !0,
        },
      ],
    },
    {
      link: ['/tracking'],
      id: 'menu.tracking',
      label: '',
      permissions: 'tracking-generate',
      iconClass: 'afaqy-icon-tracking',
      active: !1,
      default: !0,
    },
    {
      link: ['/messages'],
      id: 'menu.messages',
      label: '',
      permissions: 'units-signals',
      iconClass: 'afaqy-icon-notifications',
      collapsed: !0,
      active: !1,
      default: !0,
    },
    {
      link: ['/second-by-second'],
      id: 'menu.second-by-second',
      label: '',
      permissions: 'units-getMessages',
      iconClass: 'afaqy-icon-notifications',
      collapsed: !0,
      active: !1,
      default: !0,
    },
    {
      link: ['/units'],
      id: 'menu.units',
      label: '',
      permissions: 'units-lists',
      iconClass: 'afaqy-icon-units',
      active: !1,
      default: !0,
      subTabs: [
        { link: ['/units'], permissions: 'units-lists' },
        {
          link: ['/units/groups'],
          permissions: 'unit_groups-lists',
        },
      ],
    },
    {
      link: ['/reports'],
      id: 'menu.reports',
      label: '',
      permissions: 'user_reports-lists',
      iconClass: 'afaqy-icon-reports',
      active: !1,
      default: !0,
    },
    {
      link: '',
      id: 'menu.wasl',
      label: '',
      permissions: '',
      iconClass: 'fas fa-building',
      active: !1,
      default: !0,
      childs: [
        {
          link: ['/wasl/users'],
          id: 'menu.users',
          label: '',
          permissions: 'WASL_Custome',
          iconClass: 'afaqy-icon-users',
          active: !1,
          default: !0,
        },
        {
          link: ['/wasl/warehouses'],
          id: 'menu.warehouses',
          label: '',
          permissions: 'WASL_Custome',
          iconClass: 'afaqy-icon-users',
          active: !1,
          default: !0,
        },
        {
          link: ['/wasl/tracking/vehicles'],
          id: 'menu.wasl_tracking_vehicles',
          label: '',
          permissions: 'WASL_Custome',
          iconClass: 'afaqy-icon-units',
          active: !1,
          default: !0,
        },
        {
          link: ['/wasl/tracking/drivers'],
          id: 'menu.drivers',
          label: '',
          permissions: 'WASL_Custome',
          iconClass: 'afaqy-icon-drivers',
          active: !1,
          default: !0,
        },
        {
          link: ['/wasl/sfda/inventory-rooms'],
          id: 'menu.sfda-inventory-rooms',
          label: '',
          permissions: 'WASL_Custome',
          iconClass: 'mdi mdi-collage',
          active: !1,
          default: !0,
        },
      ],
    },
    {
      link: '',
      id: 'menu.integrations',
      label: '',
      permissions: 'amanat-lists',
      iconClass: 'fas fa-building',
      active: !1,
      default: !0,
      childs: [
        {
          link: ['/amanat'],
          id: 'menu.amanat',
          label: '',
          permissions: 'amanat-lists',
          iconClass: 'afaqy-icon-users',
          active: !1,
          default: !0,
        },
      ],
    },
    {
      link: ['/multi-unit-follow'],
      id: 'menu.multi_unit_follow',
      label: '',
      permissions: 'units-lists',
      iconClass: 'mdi mdi-window-restore',
      active: !1,
      default: !0,
    },
    {
      link: '',
      id: 'menu.dashboard',
      label: '',
      permissions: '',
      iconClass: 'fas fa-tachometer-alt',
      active: !1,
      default: !0,
      childs: [
        {
          link: ['/dashboard/stats'],
          id: 'menu.dashboardstats',
          label: '',
          permissions: 'dashboard-stats',
          iconClass: 'fas fa-car',
          active: !1,
          default: !0,
          subTabs: [
            { link: ['/dashboard/stats'], permissions: 'units-lists' },
            { link: ['/dashboard/status'], permissions: 'status-lists' },
            { link: ['/dashboard/others'], permissions: '' },
          ],
        },
        {
          link: ['/dashboard/charts/list'],
          id: 'menu.dashboardcharts',
          label: '',
          permissions: 'dashboard-charts',
          iconClass: 'fas fa-chart-pie',
          active: !1,
          default: !0,
        },
      ],
    },
    {
      link: ['/geofences'],
      id: 'menu.geofences',
      label: '',
      permissions: 'markers-lists,zones-lists,zones_groups-lists',
      iconClass: 'afaqy-icon-zone-geolocation',
      active: !1,
      default: !0,
      subTabs: [
        { link: ['/geofences'], permissions: 'zones-lists' },
        {
          link: ['/geofences/groups'],
          permissions: 'zones_groups-lists',
        },
        { link: ['/geofences/markers'], permissions: 'markers-lists' },
      ],
    },
    {
      link: ['/users'],
      id: 'menu.users',
      label: '',
      permissions: 'users-lists',
      iconClass: 'afaqy-icon-users',
      active: !1,
      default: !0,
      subTabs: [
        { link: ['/users'], permissions: 'users-lists' },
        {
          link: ['/users/tokens'],
          permissions: 'tokens-lists',
        },
      ],
    },
    {
      link: ['/customers'],
      id: 'menu.customers',
      label: '',
      permissions: 'customers-lists',
      iconClass: 'afaqy-icon-customers',
      active: !1,
      default: !0,
      subTabs: [
        { link: ['/customers'], permissions: 'customers-lists' },
        {
          link: ['/customers/types'],
          permissions: 'customer_type-lists',
        },
      ],
    },
    {
      link: '',
      id: 'menu.wastes_management',
      label: '',
      permissions: 'waste_containers-lists',
      iconClass: 'mdi mdi-recycle',
      active: !1,
      default: !0,
      childs: [
        {
          link: ['/wastes'],
          id: 'menu.waste_containers',
          label: '',
          permissions: 'waste_containers-lists',
          iconClass: 'mdi mdi-map-marker',
          active: !1,
          default: !0,
        },
        {
          link: ['/wastes/settings'],
          id: 'menu.waste_settings',
          label: '',
          permissions: 'waste_settings-get',
          iconClass: 'afaqy-icon-user-preferences',
          active: !1,
          default: !0,
        },
        {
          link: ['/wastes/plans'],
          id: 'menu.plan',
          label: '',
          permissions: 'waste_settings-get',
          iconClass: 'afaqy-icon-user-preferences',
          active: !1,
          default: !0,
        },
        {
          link: ['/wastes/plans/statistics'],
          id: 'menu.plan-statistics',
          label: '',
          permissions: 'waste_settings-get',
          iconClass: 'fas fa-car',
          active: !1,
          default: !0,
        },
      ],
    },
    {
      link: ['/', { outlets: { popup: ['chat'] } }],
      id: 'menu.chat',
      label: '',
      permissions: 'chat-lists',
      iconClass: 'afaqy-icon-support',
      active: !1,
      default: !0,
      preventRightClick: true,
    },
    {
      link: ['/trafficTickets'],
      id: 'menu.traffic_tickets',
      label: '',
      permissions: 'traffic_tickets-lists',
      iconClass: 'fas fa-traffic-light',
      active: !1,
      default: !0,
    },
    {
      link: '',
      id: 'menu.resources',
      label: '',
      permissions: '',
      iconClass: 'afaqy-icon-resources',
      selected: !1,
      active: !1,
      default: !0,
      childs: [
        {
          link: ['/tailers'],
          id: 'menu.tailers',
          label: '',
          permissions: 'tailers-lists',
          iconClass: 'afaqy-icon-trailers',
          active: !1,
          default: !0,
        },
        {
          link: ['/drivers'],
          id: 'menu.drivers',
          label: '',
          permissions: 'drivers-lists',
          iconClass: 'afaqy-icon-drivers',
          active: !1,
          default: !0,
          subTabs: [
            { link: ['/drivers'], permissions: 'drivers-lists' },
            {
              link: ['/drivers/groups'],
              permissions: 'driver_groups-lists',
            },
          ],
        },
        {
          link: ['/events'],
          id: 'menu.events',
          label: '',
          permissions: 'events-lists',
          iconClass: 'afaqy-icon-events',
          active: !1,
          default: !0,
        },
        {
          link: ['/status'],
          id: 'menu.status',
          label: '',
          permissions: 'status-lists',
          iconClass: 'afaqy-icon-status',
          active: !1,
          default: !0,
        },
        {
          link: ['/eventStatus'],
          id: 'menu.event_status',
          label: '',
          permissions: 'event_status-lists',
          iconClass: 'afaqy-icon-status',
          active: !1,
          default: !0,
        },
        {
          link: ['/locations'],
          id: 'menu.locations',
          label: '',
          permissions: '',
          iconClass: 'afaqy-icon-zone-geolocation',
          active: !1,
          default: !0,
        },
        {
          link: ['/custom-fields'],
          id: 'menu.custom_fields',
          label: '',
          permissions: 'custom_fields-lists',
          iconClass: 'mdi mdi-pencil',
          active: !1,
          default: !0,
        },
        // {
        //     link: ['/shifts'],
        //     id: "menu.shifts",
        //     label: "",
        //     permissions: "shifts-lists",
        //     iconClass: 'far fa-clock',
        //     active: !1,
        //     default: !0
        // }
      ],
    },
    {
      link: '',
      id: 'menu.settings',
      label: '',
      permissions: '',
      iconClass: 'afaqy-icon-user-preferences',
      selected: !1,
      active: !1,
      default: !0,
      childs: [
        {
          link: ['/protocolCommands'],
          id: 'menu.protocol_commands',
          label: '',
          permissions: 'protocol_commands-lists',
          iconClass: 'afaqy-icon-user-preferences',
          active: !1,
          default: !0,
        },
        {
          link: ['/protocols'],
          id: 'menu.protocols',
          label: '',
          permissions: 'protocols-lists',
          iconClass: 'afaqy-icon-user-preferences',
          active: !1,
          default: !0,
        },
        {
          link: ['/packages'],
          id: 'menu.package',
          label: '',
          permissions: 'package-lists',
          iconClass: 'afaqy-icon-user-preferences',
          active: !1,
          default: !0,
        },
        {
          link: ['/roles'],
          id: 'menu.roles_permissions',
          label: '',
          permissions: 'roles_permissions-lists',
          iconClass: 'afaqy-icon-user-preferences',
          active: !1,
          default: !0,
        },
        {
          link: ['/reports/settings'],
          id: 'menu.reports_settings',
          label: '',
          permissions: 'reports-add,reports-edit',
          iconClass: 'afaqy-icon-reports',
          active: !1,
          default: !0,
        },
      ],
    },
    {
      link: ['/auditlog'],
      id: 'menu.auditlog',
      label: '',
      permissions: 'audit-lists',
      iconClass: 'mdi mdi-file-document-box',
      active: !1,
      default: !0,
    },
  ];

  constructor(
    public translate: TranslateService,
    public service: UserService,
    private authService: AuthService,
    private rd: Renderer2,
    private router: Router
  ) {
    for (const menuItemIndex in this.menuItems) {
      if (menuItemIndex) {
        const mItem = this.menuItems[menuItemIndex];
        const permissions = [];
        /* Menu Item Childs */
        for (const childsIndex in mItem['childs']) {
          /* If Child Items has SubTabs */
          if (mItem['childs'][childsIndex]['subTabs']) {
            for (const subTabIndex in mItem['childs'][childsIndex]['subTabs']) {
              if (
                this.authService.checkPermissions(
                  mItem['childs'][childsIndex]['subTabs'][subTabIndex][
                    'permissions'
                  ]
                )
              ) {
                mItem['childs'][childsIndex].link =
                  mItem['childs'][childsIndex]['subTabs'][subTabIndex]['link'];
                let permis =
                  mItem['childs'][childsIndex]['subTabs'][subTabIndex][
                    'permissions'
                  ];
                if (mItem['childs'][childsIndex]['permissions']) {
                  permis = mItem['childs'][childsIndex]['permissions'];
                }
                mItem['childs'][childsIndex].permissions = permis;
                permissions.push(permis);
                break;
              }
            }
          } else {
            const perm = [];
            if (mItem['permissions']) {
              perm.push(mItem['permissions']);
            }
            for (let i = 0; i < mItem['childs'].length; i++) {
              perm.push(mItem['childs'][i]['permissions']);
            }
            mItem['permissions'] = perm.toString();
          }
          /* End If Child Items has SubTabs */
        }
        /* End Menu Item Childs */

        /* Give 'Module' Menu Item first Component Link Inside it */
        for (const subTabsIndex in mItem['subTabs']) {
          if (
            this.authService.checkPermissions(
              mItem['subTabs'][subTabsIndex]['permissions']
            )
          ) {
            mItem.link = mItem['subTabs'][subTabsIndex]['link'];
            permissions.push(mItem['subTabs'][subTabsIndex]['permissions']);
            break;
          }
        }
        /* End Give 'Module' Menu Item first Component Link Inside it */

        this.menuItems[menuItemIndex]['permissions'] = permissions.length
          ? permissions.join(',')
          : this.menuItems[menuItemIndex]['permissions'];
      }
    }
    this.updateActiveColumns(true);
  }

  public ngOnDestroy() {
    this.alive = false;
  }

  ngOnInit() {
    AfaqyHelper.smallWidth.pipe(takeWhile(() => this.alive)).subscribe({
      next: (status) => {
        this.smallSize = Boolean(status);
      },
    });
    this.translateLabels();
    this.translate.onLangChange.pipe(takeWhile(() => this.alive)).subscribe({
      next: () => {
        this.translateLabels();
      },
    });
  }

  search() {
    this.updateActiveColumns();
  }

  removeSelected() {
    this.activeMenus.map((item) => {
      item.selected = false;
      return item;
    });
  }

  setActive(itemID) {
    this.toggleMenu();
    let idx = this.activeMenus.findIndex((item) => itemID == item.id);
    if (idx) {
      this.activeMenus[idx]['selected'] = true;
    }
  }

  translateLabels() {
    let self = this;
    for (const x in this.menuItems) {
      self.menuItems[x].label = this.translate.instant(self.menuItems[x].id);
      if (self.menuItems[x]['childs']) {
        for (let sx in self.menuItems[x]['childs']) {
          self.menuItems[x]['childs'][sx].label = this.translate.instant(
            self.menuItems[x]['childs'][sx].id
          );
        }
      }
    }
  }

  toggleSubitems(event, item) {
    for (let x in this.activeMenus) {
      let collapsed = true;
      if (item.id == this.activeMenus[x].id) {
        collapsed = !this.activeMenus[x]['collapsed'];
      }
      this.activeMenus[x]['collapsed'] = collapsed;
    }
  }

  toggleMenu() {
    this.removeSelected();
    // setTimeout(() => {
    //     document.getElementById("sidebar-key").click();
    // }, 500);
    // Check if the data section is not appear
    if (this.fullMap) {
      // Open map from the HomeComponent as > Parent to child
      this.dataMapStatus.emit(!this.fullMap);
    }
    if (this.smallSize && this.menuClass == '') {
      this.menuClass = 'wsoffcanvasopener';
    } else {
      this.menuClass = '';
      this.searchTerms = '';
      this.updateActiveColumns();
    }
  }

  /* new Function for toggling menu on off only // avoid checking for all instances of the previous function */
  toggleMenuOnly() {
    this.MenuOpen = !this.MenuOpen;
    this.MenuState.emit(this.MenuOpen);
  }

  /* helper function for setting menu state open or closed from the menu itself */
  toggleMenuhelper(status = false) {
    this.MenuOpen = !this.MenuOpen;
    this.MenuState.emit(status);
  }

  updateActiveColumns(updates = false) {
    let activeColumns = [];
    let savedDef = []; // this.service.getUserPreferences('site', this.cid);
    for (let key in this.menuItems) {
      if (updates) {
        if (savedDef.length) {
          if (
            savedDef.findIndex((value) => {
              return this.menuItems[key].id == value;
            }) != -1
          ) {
            this.menuItems[key].active = true;
          }
        } else if (this.menuItems[key].default) {
          this.menuItems[key].active = true;
        }
        for (let subkey in this.menuItems[key]['childs']) {
          if (savedDef.length) {
            if (
              savedDef.findIndex((value) => {
                return this.menuItems[key]['childs'][subkey].id == value;
              }) != -1
            ) {
              this.menuItems[key]['childs'][subkey]['active'] = true;
            }
          }
          if (this.menuItems[key].default) {
            this.router.events.subscribe({
              next: (navigation) => {
                if (navigation instanceof NavigationEnd) {
                  if (
                    this.menuItems[key]['childs'][subkey].link[0] ==
                    navigation.url
                  ) {
                    this.menuItems[key]['childs'][subkey].active = true;
                    this.menuItems[key].collapsed = true;
                  } else {
                    this.menuItems[key]['childs'][subkey].active = false;
                  }
                }
              },
            });
          }
        }
      }
      if (this.menuItems[key].active) {
        activeColumns.push(this.menuItems[key]);
      }
    }
    this.authService.loadedSession.subscribe({
      next: (flag) => {
        if (flag) {
          let options = this.activeMenus.map(function (item) {
            return item.id;
          });
          this.service.updateUserPreferences('site', this.cid, options);
        }
      },
    });
    if (this.searchTerms) {
      const terms = this.searchTerms.trim().toLowerCase();
      let searchResult = [];
      for (let key in activeColumns) {
        let exist = false;
        let item = JSON.parse(JSON.stringify(activeColumns[key]));
        let label = item['label'].toLowerCase();
        if (label.indexOf(terms) !== -1) {
          exist = true;
        }
        let subitems = [];
        for (let subKey in item['childs']) {
          let sublabel = item['childs'][subKey]['label'].toLowerCase();
          if (sublabel.indexOf(terms) !== -1) {
            exist = true;
            subitems.push(item['childs'][subKey]);
          }
        }
        item.childs = subitems;
        if (exist) {
          searchResult.push(item);
        }
      }
      activeColumns = searchResult;
    }
    this.activeMenus = activeColumns;
  }

  updateCols($event: any) {
    this.menuItems[$event.index].active = $event.status;
    this.updateActiveColumns();
  }

  // Prevent right-click if menu item route will open in popup
  onRightClick(event: Event, item: any): void {
    if (!event || !item) return;
    if (item.preventRightClick) event.preventDefault();
  }
}
